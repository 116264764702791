import React from "react";
import { View, StyleSheet as StyleSheetCreator } from "react-native";
import { StyleSheet } from "../../theme/StyleSheet";

export interface BoxProps {
  styleSheet?: Partial<StyleSheet>;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export const Box = ({ children, styleSheet }: BoxProps) => {
  const styles = StyleSheetCreator.create({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: styleSheet as any,
  });

  return <View style={styles.component}>{children}</View>;
};
