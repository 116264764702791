import React from "react";
import { Text as RN_Text, StyleSheet as StyleSheetCreator } from "react-native";
import { StyleSheet } from "../../theme/StyleSheet";

export interface TextProps {
  styleSheet?: Partial<StyleSheet>;
  children: React.ReactNode;
}
export const Text = ({ children, styleSheet }: TextProps) => {
  const styles = StyleSheetCreator.create({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: styleSheet as any,
  });

  return <RN_Text style={styles.component}>{children}</RN_Text>;
};
