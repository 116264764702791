import React from "react";
import {
  TouchableOpacity,
  StyleSheet as StyleSheetCreator,
  GestureResponderEvent,
} from "react-native";
import { StyleSheet } from "../../theme/StyleSheet";

export interface TouchableProps {
  styleSheet?: Partial<StyleSheet>;
  children?: React.ReactNode;
  onTap?: ((event: GestureResponderEvent) => void) | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export const Touchable = ({
  children,
  styleSheet,
  ...props
}: TouchableProps) => {
  const styles = StyleSheetCreator.create({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: styleSheet as any,
  });

  return (
    <TouchableOpacity style={styles.component} {...props}>
      {children}
    </TouchableOpacity>
  );
};
